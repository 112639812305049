const meta = { group: 'jobs' };
export default [
  {
    meta,
    path: "/cv/:user_id",
    name: "ShowCurriculum",
    component: () =>
      import(/* webpackChunkName: "jobs" */ "./views/ShowCurriculum.vue"),
  },
  {
    meta,
    path: "/cv/:user_id/muuda",
    name: "SaveCurriculum",
    component: () =>
      import(/* webpackChunkName: "jobs" */ "./views/SaveCurriculum.vue"),
  },
  {
    meta,
    path: "/kandidaadid",
    name: "Curriculums",
    component: () =>
      import(/* webpackChunkName: "jobs" */ "./views/Curriculums.vue"),
  },
]