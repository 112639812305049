<template>
  <div>
    <div class="pl-3">
      <v-menu bottom offset-y left :max-width="400" :width="350" :max-height="600" z-index="60">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small fab icon class="mr-4">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <MyAccountDialogContent />
      </v-menu>
    </div>
  </div>
</template>

<script>
import MyAccountDialogContent from "./MyAccountDialogContent.vue";
import LogoutBtn from "./LogoutBtn";

export default {
  components: {
    LogoutBtn,
    MyAccountDialogContent,
  },
  computed: {
    businesses() {
      return this._.get(this.$store.state.auth, ["user", "managed_businesses"], false);
    },
  },
  data() {
    return {
      key: 1,
      btnProps: {
        // rounded: true,
        text: true,
        icon: false,
        fab: false,
        outlined: true,
        block: true,
      },
    };
  },
};
</script>
