const meta = { group: 'jobs' };
export default [
  {
    meta,
    path: `/toopakkumised/otsi`,
    name: "SearchJoblisting",
    component: () =>
      import(/* webpackChunkName: "jobs" */ "../joblisting/views/SearchJoblisting.vue"),
  },

  {
    meta,
    path: `/toopakkumised/meelespea`,
    name: "BookmarkedJoblistings",
    component: () =>
      import(/* webpackChunkName: "jobs" */ "../joblisting/views/BookmarkedJoblistings.vue"),
  },

  {
    meta,
    path: `/toopakkumised/:business_slug/store`,
    name: "CreateJoblisting",
    component: () =>
      import(/* webpackChunkName: "jobs" */ "../joblisting/views/SaveJoblisting.vue"),
  },

  {
    meta,
    path: `/toopakkumised/:business_slug/:joblisting_id/muuda`,
    name: "EditJoblisting",
    component: () =>
      import(/* webpackChunkName: "jobs" */ "../joblisting/views/SaveJoblisting.vue"),
  },

  {
    meta,
    path: `/toopakkumised/:business_slug`,
    name: "BusinessJoblistings",
    component: () =>
      import(/* webpackChunkName: "jobs" */ "./views/BusinessJoblistings.vue"),
  },

  {
    meta,
    path: `/toopakkumised/:business_slug/:joblisting_id`,
    name: "ShowJoblisting",
    component: () =>
      import(/* webpackChunkName: "jobs" */ "./views/ShowJoblisting.vue"),
  },

  {
    meta,
    path: `/toopakkumised/:business_slug/:joblisting_id/candidates`,
    name: "JoblistingApplications",
    component: () =>
      import(/* webpackChunkName: "jobs" */ "./views/JoblistingApplications.vue"),
  },


];
// 