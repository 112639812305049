import auth from "@/blocks/auth/routes.js";
import businesses from "@/blocks/business/routes.js";
import dev from "@/blocks/_dev/routes.js";
import news from "@/blocks/news/routes.js";
import jobs from "@/blocks/jobs/routes.js";
import page from "@/blocks/page/routes.js";

/**
 * This list of all routes should be manually kept in sync with
 * App/Http/Controllers/ShowFrontsideController.php.
 */
const routes = [
  ...dev,
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "business" */ "@/blocks/business/views/Businesses.vue"),
    meta: {title: "Ehitussektor"},
  },
  ...auth,
  ...businesses,
  ...news,
  ...jobs,
  ...page, // must be at the end before 'NotFound'
  {
    path: "*",
    name: "NotFound",
    component: () => import(/* webpackChunkName: "main" */ "@/blocks/not-found/views/NotFound.vue"),
    meta: {title: "Lehte ei leitud"},
  },
];

export default routes;
