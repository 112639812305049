export default [
  {
    path: `/sisene`,
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "main" */ "./views/Authenticate.vue"),
    meta: { title: "Sisene" },
  },
  {
    path: `/liitu`,
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "main" */ "./views/Authenticate.vue"),
    meta: { title: "Liitu" },
  },
  {
    path: `/taasta-parool`,
    name: "ResetPassword",
    component: () =>
      import(/* webpackChunkName: "main" */ "./views/ResetPassword.vue"),
    meta: { title: "Parooli värskendamine" },
  },
];
