<style lang="scss">
// @import "@/dependencies.scss";
</style>

<template>
  <v-dialog v-bind="{ 'max-width': '600', ...$attrs }" v-model="showTermsDialog">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }">
        <v-btn class="mt-3" small outlined text tile v-bind="attrs" v-on="on">Loe tingimusi</v-btn>
      </slot>
    </template>
    <DynamicContent
      v-bind="cardProps"
      template="CardTemplate"
      path="/privaatsuspoliitika"
      disable-contact-form
    >
      <template #card-footer>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small @click="showTermsDialog = false">
            <v-icon left>mdi-close</v-icon>
            Sulge
          </v-btn>
          <v-btn
            color="success"
            @click="
              showTermsDialog = false;
              $emit('accepted', true);
            "
          >
            Nõustu
          </v-btn>
        </v-card-actions>
      </template>
    </DynamicContent>
  </v-dialog>
</template>

<script>
import DynamicContent from "@/blocks/page/components/DynamicContent";
export default {
  components: {
    DynamicContent,
  },
  props: {
    cardProps: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showTermsDialog: false,
    };
  },
};
</script>
