import {
  get,
  has,
  isObject,
  clone,
  cloneDeep,
  set,
  unset,
  intersection,
  replace,
} from "lodash";
const _ = {
  get,
  has,
  isObject,
  clone,
  cloneDeep,
  set,
  unset,
  intersection,
  replace,
};

export const lodashPlugin = {
  install(Vue) {
    Vue.prototype._ = _;
  },
};

export default _;
