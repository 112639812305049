<template functional>
  <header class="mt-12 mb-6 text-center">
    <h1 :class="'font-weight-light mb-2 text-' + props.heading">
      <slot name="title">
        <span v-text="props.title"></span>
      </slot>
    </h1>
    <div class="d-flex justify-center">
      <div class="font-weight-light text-subtitle-1" style="max-width: 700px !important">
        <slot>
          <span v-text="props.subtitle"></span>
        </slot>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    heading: {
      type: String,
      default: "h2",
    },
  },
};
</script>
