import {
  SET,
  //   ADD_ITEM_START,
  //   ADD_ITEM,
  ADD_OR_UPDATE_ITEM,
  //   UPDATE_ITEM,
  //   REMOVE_ITEM,
} from "@/store/common/_mutations";
import _ from "@/common/lodash-plugin";
import _getters from "@/store/common/_getters";

const state = {
  categories: [],
  posts: [],
};

const getters = {
  ..._getters,
  categoryOf: (state, getters, rootState, rootGetters) => (post) => {
    if (!post) {
      console.warn("Post can not be empty. Post: ", post);
      return { title: "", long_title: "", slug: "-" };
    }
    return getters.findBy("categories", "slug", post.category_slug);
  },
};

const mutations = {
  SET: (state, payload) => {
    SET(state, payload);
  },
  //   ADD_POST: (state, item) => {
  //     ADD_ITEM(state, item, "posts");
  //   },
  //   ADD_POST_START: (state, item) => {
  //     ADD_ITEM_START(state, item, "posts");
  //   },
  ADD_OR_UPDATE_POST: (state, updatedPost) => {
    ADD_OR_UPDATE_ITEM(state, updatedPost, "posts");
  },
  //   UPDATE_POST: (state, updatedItem) => {
  //     UPDATE_ITEM(state, updatedItem, "posts");
  //   },
  //   REMOVE_POST: (state, idOrItem) => {
  //     REMOVE_ITEM(state, idOrItem, "posts");
  //   },
};

const actions = {
  syncPosts({ commit, getters }, posts) {
    for (const post of posts) {
      commit("ADD_OR_UPDATE_POST", post);
    }
  },

  sortPosts({ commit }) {
    commit("SET", {
      posts: _.sortBy(state.posts, ["date", "created_at", "id"]),
    });
  },

  async fetchNewsData({ commit, dispatch, rootGetters }) {
    if (rootGetters.isLoading("news")) return;

    commit("START_LOADING", "news");
    try {
      const response = await this.axios.get("page/news");
      dispatch("handleFetchSuccess", response.data);
    } finally {
      commit("STOP_LOADING", "news");
    }
  },

  handleFetchSuccess({ dispatch }, data) {
    dispatch("syncPosts", data.posts);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
