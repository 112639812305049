<template>
  <v-sheet
    block
    v-bind="{ ...$attrs, ...ad }"
    elevation="0"
    v-show="$store.state.auth.user && $store.state.auth.user.is_admin"
  >
    <div class="d-flex justify-center align-center w-100 h-100">
      <div class="text-h5 mx-3">
        <span v-if="type !== 'AdaptiveBanner'">{{ ad.width }} x {{ ad.height }} –</span>
        {{ ad.title }}
        <div>
          <em>
            <small>{{ $route.name }}</small>
          </em>
        </div>
      </div>
      <slot></slot>
      <v-btn class="float-right" text icon small dark>
        {{ $vuetify.breakpoint.name }}
      </v-btn>
    </div>
  </v-sheet>
</template>

<script>
const ads = {
  AdaptiveBanner: {
    width: "100%",
    height: "130px",
    color: "grey darken-2",
    app: true,
    dark: true,
  },
  Leaderboard: {
    width: 728,
    height: 90,
    color: "brown darken-2",
    dark: true,
  },
  LargeLeaderboard: {
    width: 970,
    height: 90,
    color: "indigo darken-4",
    dark: true,
  },
  InlineRectangle: {
    width: 300,
    height: 250,
    dark: true,
    color: "grey darken-2",
  },
  LargeSkyscraper: {
    width: 300,
    height: 600,
    dark: true,
    color: "grey darken-4",
  },
};

const toSnake = (str) =>
  str.replace(/[A-Z]/g, (letter, index) => {
    return index == 0 ? letter : " " + letter;
  });

export default {
  props: {
    type: {
      type: String,
      default: "AdaptiveBanner",
    },
  },
  computed: {
    ad() {
      const ad = ads[this.type];
      if (!ad.title) ad.title = toSnake(this.type);
      return ad;
    },
  },
};
</script>

<style>
</style>
