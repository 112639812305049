export default [
  {
    path: "/typography",
    name: "Typography",
    component: () =>
      import(/* webpackChunkName: "development" */ "./views/Typography.vue"),
  },
  {
    path: "/colors",
    name: "MaterialColors",
    component: () =>
      import(/* webpackChunkName: "development" */ "./views/MaterialColors.vue"),
  },
  {
    path: "/routes",
    name: "Routes",
    component: () =>
      import(/* webpackChunkName: "development" */ "./views/Routes.vue"),
  },
];
