
<template>
  <component v-bind="$attrs" :is="templateComponent">
    <template #title>
      {{ title }}
    </template>

    <div v-if="loaded">
      <HtmlContent :content="content"></HtmlContent>

      <ExpansionPanel
        v-if="data.faqs && Array.isArray(data.faqs) && data.faqs.length"
        :items="data.faqs"
      />

      <v-alert v-if="data.alert_text" tile type="info" class=" mb-3">
        <div class="text-h6" v-html="data.alert_text"></div>
      </v-alert>
      <PageAuthForm v-if="data.show_auth_form && !disableAuthForm" />

      <LandlordContactForm v-if="data.show_contact_form && !disableContactForm" />
    </div>
    <v-skeleton-loader
      v-else
      class="mx-auto"
      type="text, sentences, text, text, paragraph"
    ></v-skeleton-loader>

    <template #card-footer>
      <slot name="card-footer"></slot>
    </template>
  </component>
</template>

<script>
import PageAuthForm from "./PageAuthForm.vue";
import ExpansionPanel from "./ExpansionPanel.vue";
import LandlordContactForm from "@/blocks/global/components/LandlordContactForm";
import HtmlContent from "@/blocks/global/components/HtmlContent";
import DefaultTemplate from "./templates/DefaultTemplate";
import WideTemplate from "./templates/WideTemplate"; // dynamic - do not remove
import WithSidebarTemplate from "./templates/WithSidebarTemplate"; // dynamic - do not remove
import CardTemplate from "./templates/CardTemplate"; // dynamic - do not remove
export default {
  components: {
    HtmlContent,
    LandlordContactForm,
    ExpansionPanel,
    PageAuthForm,
  },

  props: {
    path: {
      type: String,
      required: true,
    },
    template: {
      type: String,
      default: null,
    },
    disableContactForm: {
      type: Boolean,
      default: false,
    },
    disableAuthForm: {
      type: Boolean,
      default: false,
    },
    disableFaqs: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loaded: false,
      title: this.$store.state.page.routes[this.path],
      content: null,
      data: null,
    };
  },

  created() {
    this.fetchContent();
  },

  computed: {
    templateComponent() {
      if (this.template) return this.getTemplate(this.template);

      return this.getTemplate(this.data ? this.data.template : null);
    },
  },

  methods: {
    getTemplate(name) {
      if (name === "lai" || name === "WideTemplate") {
        return WideTemplate;
      } else if (name === "reklaamiplokiga" || name === "WithSidebarTemplate") {
        return WithSidebarTemplate;
      } else if (name === "CardTemplate") {
        return CardTemplate;
      } else {
        return DefaultTemplate;
      }
    },
    async fetchContent() {
      this.$store.commit("START_LOADING", this.path);
      const response = await this.axios.get("page-content" + this.path);
      this.content = response.data.content;
      this.data = response.data;
      this.$emit("loaded", response.data);
      this.loaded = true;
      this.$store.commit("STOP_LOADING", this.path);
    },
  },
};
</script>
