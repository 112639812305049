<style lang="scss">
@import "@/dependencies.scss";

.contact-form {
  .mesi {
    display: none;
  }
  .message textarea {
    min-height: calc(208px - 12px + 2px);
  }
  &.with-business-name {
    .message textarea {
      min-height: calc(284px - 12px + 3px);
    }
  }
  @include media-breakpoint-down(sm) {
    .message textarea {
      min-height: initial;
    }
    .form-first-part {
      margin-bottom: 0;
      padding-bottom: 0;
    }
    .form-second-part {
      margin-top: 0;
      padding-top: 0;
    }
  }
}
</style>

<template>
  <form class="contact-form" :class="{ 'with-business-name': asksBusinessName }">
    <v-sheet tag="section">
      <v-card-title v-if="title">
        <h3 class="text-h4">{{ title }}</h3>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" class="form-first-part">
            <!-- <div class="text--secondary text-body-2 ml-0 mb-5 d-md-none">
              * Palun täida kõik väljad!
            </div> -->
            <div>
              <v-text-field
                :disabled="form.success"
                class="name mb-5"
                type="text"
                label="Sinu nimi"
                v-model="form.name"
                :error="form.state('name')"
                :error-messages="form.error('name')"
                v-bind="inputProps"
              />

              <!-- Honeypot -->
              <v-text-field
                :disabled="form.success"
                class="mesi mb-5"
                type="text"
                label="mesi"
                v-model="form.mesi"
                :error="form.state('mesi')"
                :error-messages="form.error('mesi')"
                v-bind="inputProps"
              />

              <v-text-field
                :disabled="form.success"
                class="email mb-5"
                type="email"
                label="Sinu email"
                v-model="form.email"
                :error="form.state('email')"
                :error-messages="form.error('email')"
                v-bind="inputProps"
              />

              <v-text-field
                :disabled="form.success"
                class="tel mb-5"
                type="tel"
                label="Sinu telefoni number"
                v-model="form.tel"
                :error="form.state('tel')"
                :error-messages="form.error('tel')"
                v-bind="inputProps"
              />

              <v-text-field
                v-if="asksBusinessName"
                :disabled="form.success"
                class="business"
                type="text"
                label="Ettevõtte nimi"
                v-model="form.business"
                :error="form.state('business')"
                :error-messages="form.error('business')"
                v-bind="inputProps"
              />
            </div>
          </v-col>
          <v-col cols="12" md="6" class="form-second-part">
            <v-textarea
              :disabled="form.success"
              class="message"
              label="Sinu sõnum"
              v-model="form.message"
              :error="form.state('message')"
              :error-messages="form.error('message')"
              v-bind="inputProps"
            ></v-textarea>
          </v-col>

          <v-col cols="12" class="my-0 py-0" v-if="canAttachFiles && showAttachmentsInput">
            <AttachmentsInput
              :disabled="form.success"
              @change="form.attachments_directory = $event"
              :business="business"
            ></AttachmentsInput>
          </v-col>

          <v-col cols="12" md="6" class="form-first-part pt-0">
            <!-- <div class="text--secondary text-body-2 ml-3 d-none d-md-block">
              Palun täida kõik väljad!
            </div> -->
            <div v-if="canAttachFiles">
              <base-btn
                small
                outlined
                text
                @click="showAttachmentsInput = true"
                v-if="!showAttachmentsInput"
              >
                <v-icon small left>mdi-paperclip</v-icon>
                Manuste sisetamine
              </base-btn>
            </div>
          </v-col>

          <v-col cols="12" md="6" class="form-second-part pt-0">
            <base-btn
              :disabled="form.success"
              class="accent"
              type="submit"
              @click.prevent="form.submit()"
              :loading="form.loading"
              block
              large
              elevation="0"
            >
              <div class="d-flex align-center" v-if="form.success">
                <v-icon left>mdi-check</v-icon>
                Saadetud
              </div>
              <span v-else>Saada</span>
            </base-btn>
          </v-col>

          <v-col
            key="error"
            cols="12"
            v-if="form.errors.message"
            class="w-100 d-flex justify-center"
          >
            <v-alert tile type="error">{{ form.errors.message }}</v-alert>
          </v-col>
          <v-col
            key="success"
            cols="12"
            v-else-if="form.successMessage"
            class="w-100 d-flex justify-center"
          >
            <v-alert tile type="success">{{ form.successMessage }}</v-alert>
          </v-col>
        </v-row>
      </v-card-text>
    </v-sheet>
  </form>
</template>

<script>
import AttachmentsInput from "./AttachmentsInput.vue";
import Form from "@/common/form";
export default {
  components: {
    AttachmentsInput,
  },
  props: {
    business: {
      type: Object,
      default: null,
    },
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    asksBusinessName: {
      type: Boolean,
      default: true,
    },
    canAttachFiles: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    name() {
      return this._.get(this.$store.state.auth.user, "name", "");
    },
    email() {
      return this._.get(this.$store.state.auth.user, "email", "");
    },
  },

  watch: {
    name: {
      immediate: true,
      handler(name) {
        if (name) this.form.name = name;
      },
    },
    email: {
      immediate: true,
      handler(email) {
        if (email) this.form.email = email;
      },
    },
  },
  data() {
    return {
      showAttachmentsInput: false,
      form: new Form(
        {
          name: "",
          email: "",
          tel: "",
          message: "",
          business: "",
          mesi: "",
          attachments_directory: null,
        },
        {
          method: "POST",
          url: this.url,
        },
      ),
      inputProps: {
        outlined: true,
        filled: true,
        hideDetails: "auto",
      },
    };
  },
};
</script>

