<template>
  <v-sheet
    color="grey darken-4"
    height="52px"
    :class="{ 'white--text': darkNavbar !== '0' }"
  >
    <v-container class="py-0 fill-height">
      <div class="d-flex align-center w-100 h-100 text-no-wrap" style="font-size: 14px">
        <div class="" style="max-width: 100%">
          <v-slide-group :dark="darkNavbar !== '0'" multiple show-arrows>
            <v-slide-item v-for="item in bottomNavItems" :key="item.title" class="mr-1">
              <router-link
                :to="item.to"
                class="py-3 px-3"
                :class="{
                  link__active: $route.name === item.to.name,
                  'white--text link-white': darkNavbar != '0',
                  'black--text link-black': darkNavbar == '0',
                }"
                v-ripple="{ class: `white--text` }"
              >
                <v-icon x-small color="white" class="mr-1" v-if="item.icon">
                  {{ item.icon }}
                </v-icon>
                <span>{{ item.title }}</span>
              </router-link>
            </v-slide-item>
          </v-slide-group>
        </div>
      </div>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  computed: {
    darkNavbar() {
      return true;
    },
    postCategories() {
      return this.$store.state.news.categories;
    },
    bottomNavItems() {
      const items = [
        {
          title: "Tööpakkumised",
          to: { name: "Jobs" },
        },
        {
          title: "Jälgitavad tööpakkumised",
          to: { name: "BookmarkedJoblistings" },
        },
        {
          title: "Leia tööpakkumine",
          to: { name: "SearchJoblisting" },
        },
      ];

      if (this._.get(this.$store.state.auth.user, "is_business_manager")) {
        items.push({
          title: "Kandidaadid",
          to: { name: "Curriculums" },
        });
      }

      return items;
    },
    socialMediaUrls() {
      return {
        facebook: this.$store.state.settings.facebook,
        twitter: this.$store.state.settings.twitter,
        instagram: this.$store.state.settings.instagram,
        youtube: this.$store.state.settings.youtube,
      };
    },
    today() {
      return new Date().toLocaleDateString("et-EE", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
};
</script>
