import Vue from "vue";
Vue.config.productionTip = false;
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./common/auto-components";
import vuetify from "./plugins/vuetify";
require("@/scss/main.scss");

// Lodash
import { lodashPlugin } from "@/common/lodash-plugin";
Vue.use(lodashPlugin);

// Custom helpers
import { helpersPlugin } from "@/common/helpers-plugin";
Vue.use(helpersPlugin);

// Axios
import Axios from "@/common/axios";
Axios.init();

// Vue meta plugin
import VueMeta from "vue-meta";
Vue.use(VueMeta, { keyName: "head", attribute: "data-x-meta" });

// Clamp plugin
import VClamp from "vue-clamp";
Vue.component("v-clamp", VClamp);

// Social sharing plugin
import VueSocialSharing from "vue-social-sharing";
Vue.use(VueSocialSharing);

// Vuetify dialog plugin
import VuetifyDialog from "./plugins/dialog/index";
Vue.use(VuetifyDialog, {
  context: { vuetify },
});

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
