export const getters = {
  getBy: (state) => (fieldName, key, value) => {
    return getBy(state[fieldName], key, value);
  },
  findBy: (state) => (fieldName, key, value) => {
    return findBy(state[fieldName], key, value);
  },
  has: (state) => (fieldName, key, value) => {
    return has(state[fieldName], key, value);
  },
};

export const findBy = function(items, key, value) {
  return items.find((items) => items[key] === value);
};

export const has = function(items, key, value) {
  return items.some((item) => item[key] === value);
};

export const getBy = function(items, key, value) {
  if (Array.isArray(value)) {
    return items.filter((item) => value.includes(item[key]));
  }
  return items.filter((item) => item[key] === value);
};

export default getters;
