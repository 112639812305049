<style lang="scss">
.text_content {
  img {
    // margin-bottom: 16px;
    height: 100% !important;
    max-width: 100%;
    max-height: 100%;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 16px;
    a {
      color: initial;
    }
  }
  input[type="button"] {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    font-weight: 500;
    letter-spacing: 0.0892857143em;
    justify-content: center;
    outline: 0;
    position: relative;
    text-decoration: none;
    text-indent: 0.0892857143em;
    text-transform: uppercase;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    height: 36px;
    min-width: 64px;
    padding: 0 16px;
    border: thin solid currentColor;
  }
}
</style>

<template>
  <div class="text_content text-body-1" v-html="parsedContent"></div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      required: true,
    },
    replaceables: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    parsedContent() {
      const replace = this.replaceables.concat([
        ["<h1>", '<h1 class="text-h2">'],
        ["<h2>", '<h2 class="text-h3">'],
        ["<h3>", '<h3 class="text-h4">'],
        ["<h4>", '<h4 class="text-h5">'],
        ["<h5>", '<h5 class="text-h6">'],
        ["<h6>", '<h6 class="title">'],
      ]);

      let text = this.content;

      for (const el of replace) {
        text = text.replaceAll(el[0], el[1]);
      }

      return text;
    },
  },
};
</script>
