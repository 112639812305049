<template>
  <BusinesseAvatarsList v-bind="$attrs" :businesses="$store.state.business.popularBusinesses" />
</template>

<script>
import BusinesseAvatarsList from "./BusinesseAvatarsList";
export default {
  components: {
    BusinesseAvatarsList,
  },
};
</script>
