export default [
  {
    meta: {
      title: "Uudised",
      description: "Uudiste meta kirjeldus",
      keywords: "keyword1, keyword2",
    },
    path: "/uudised",
    name: "News",
    component: () => import(/* webpackChunkName: "news" */ "./views/News.vue"),
  },
  {
    meta: {
      title: "Artikkel",
      description: "Artikli meta kirjeldus",
      keywords: "keyword1, keyword2",
    },
    name: "NewsPost",
    path: "/uudis/:id/:slug?",
    component: () =>
      import(/* webpackChunkName: "news" */ "./views/NewsPost.vue"),
  },
  {
    meta: { title: "Kategooria" },
    name: "NewsCategory",
    path: "/uudised/:slug",
    component: () =>
      import(/* webpackChunkName: "news" */ "./views/NewsCategory.vue"),
  },
  {
    meta: { title: "Test" },
    path: "/test",
    name: "Test",
    component: () => import(/* webpackChunkName: "main" */ "./views/Test.vue"),
  },
];
