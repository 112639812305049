<template>
  <div>
    <h3 class="text-h5 mt-3 mb-2">Korduma kippuvad küsimused</h3>

    <v-expansion-panels v-model="panel" multiple flat tile>
      <template v-for="(item, i) in items">
        <v-expansion-panel :key="i" v-if="item.title && item.content">
          <v-expansion-panel-header color="grey lighten-3" class="my-1 py-1 rounded">
            <h4 class="text-h6">{{ item.title }}</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-html="item.content"></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      panel: [],
    };
  },
};
</script>

<style lang="scss">
.v-expansion-panel-header {
  //   background-color: var(--v-background-darken1);
}
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 56px !important;
}
</style>