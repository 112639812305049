

<template>
  <div class="socmed-side-login" :class="{ colored: !transparent }">
    <slot name="header"></slot>
    <v-btn
      v-for="provider in ['google', 'facebook']"
      :key="provider"
      v-bind="{
        small: true,
        tile: true,
        dark: true,
        ...$attrs,
      }"
      :class="provider + '-btn ma-1 ' + btnClass"
      :style="btnStyle"
      @click="onSocialAuthBtnClick(provider)"
    >
      <v-icon left>{{ "mdi-" + provider }}</v-icon>
      {{ $t(translation, { provider }) }}
    </v-btn>
    <slot name="footer"></slot>
  </div>
</template>

<script>
import openPopupWindow from "@/common/popup-window-opener";
import siteDomain from "@/common/site-domain";

export default {
  props: {
    btnClass: {
      type: String,
      default: "",
    },
    translation: {
      type: String,
      default: "Continue with {provider}",
    },
    btnStyle: {
      type: String,
      default: null,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      messages: {
        "Continue with {provider}": "Sisene {provider} kontoga",
        "{provider}": "{provider}",
      },
    };
  },
  methods: {
    $t(getter, opts) {
      let message = this.messages[getter] ? this.messages[getter] : getter;
      for (let [key, value] of Object.entries(opts)) {
        message = message.replace("{" + key + "}", value);
      }
      return message;
    },
    onSocialAuthBtnClick(provider) {
      // if (process.env.NODE_ENV === "development") {
      /**
       *  Messages between windows dont work on development because of different url(port).
       *  So we just imitate success.
       */
      //   this.onUserReceived({
      //     data: {
      //       user: {
      //         id: 5,
      //         email: "k22rma@gmail.com",
      //         tel: null,
      //         name: "Kristjan Käärma",
      //         is_admin: false,
      //         bookmarked_business_ids: [],
      //         business_bookmarks: [],
      //       },
      //       intended: null,
      //     },
      //   });
      //   return;
      // }

      openPopupWindow(
        siteDomain + "social/login/" + provider,
        "Login with " + provider,
        this.onUserReceived,
      );
    },

    /**
     * Handles user received from popup window.
     *
     * event.data includes `user` object and `intended` url(or null).
     *
     * @var MessageEvent event
     */
    onUserReceived(event) {
      this.$emit("authenticated", event.data);
    },
  },
};
</script>

<style lang="scss" scoped>
.colored {
  .twitter-btn {
    background: #28aae1 !important;
    &:hover {
      background: darken(#28aae1, 10%) !important;
    }
  }
  .facebook-btn {
    background: #3b579d !important;
    &:hover {
      background: darken(#3b579d, 5%) !important;
    }
  }
  .google-btn {
    background: #dd493c !important;
    &:hover {
      background: darken(#dd493c, 5%) !important;
    }
  }
}
</style>
