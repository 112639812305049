import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
// import updateRouteMeta from "./update-route-meta";
Vue.use(VueRouter);
import store from "./../store";

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
  /**
   * To keep the scroll position when route changes
   */
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // We have savedPosition usually if moving back.
      return savedPosition;
    } else {
      // Pass in query parameter keep=1 to keep the scroll position as it is.
      if (to.query.keep) return;

      // Otherwise scroll to top.
      return { x: 0, y: 0 }; // { top: 0, left: 0 }; for vue3 version of router
    }
  },
});

router.beforeEach((to, from, savedPosition) => {
  store.commit("START_LOADING", "routeChange");
  //   updateRouteMeta(to, from, savedPosition);
  savedPosition();
});

router.afterEach((to, from, savedPosition) => {
  setTimeout(() => {
    store.commit("STOP_LOADING", "routeChange");
  }, 350);
});

export default router;
