import _ from "lodash";
import axios from "axios";
import injectables from "@/common/injectables";

const injectSchema = [
  {
    injectedDataKey: "csrf",
    set: ["csrf"],
  },
  {
    injectedDataKey: "user",
    action: "auth/setUser",
  },
  {
    injectedDataKey: "page_routes",
    set: ["page", "routes"],
  },
  {
    injectedDataKey: "advertisement",
    set: ["advertisement", "items"],
  },
  {
    injectedDataKey: "footer_about_items",
    set: ["footerAboutItems"],
  },
  {
    injectedDataKey: "settings",
    set: ["settings"],
  },
  {
    injectedDataKey: "content",
    set: ["content"],
  },
  {
    injectedDataKey: "postCategories",
    set: ["news", "categories"],
  },
  {
    injectedDataKey: "news",
    action: "news/handleFetchSuccess",
  },
  {
    injectedDataKey: "business",
    commit: "business/SET",
  },
  {
    injectedDataKey: "published_joblistings_total_count",
    set: ["joblisting", "publishedJoblistingsTotalCount"],
  },
];

class InitializeApp {
  constructor($store) {
    this.injectables = injectables;
    this.$store = $store;
    this.isUnauthenticated = null;
  }

  async init() {
    /**
     * 1. First lets inject all data or if no data is available, then
     * fetch injectables from single route to load app faster.
     */
    await this.inject();

    /**
     * 1.1 If unauthenticated, then we can just finish here
     */
    if (this.isUnauthenticated) return;

    /**
     * 2. Most of following dispatches will skip because we have already injected the data,
     * but if something is missing then it will be fetched alacarte.
     *
     * 2.1 First we fetch those that are (more or less)dependancies for other data.
     */
    await this.fetchDependencies();

    /**
     * 2.2 After dependencies are fetched or injected we continue fetching and initializing separate parts of app syncronously. Most dispatches will again just skip.
     */
    await this.fetchAndInitDependables();

    return "OK";
  }

  async inject() {
    if (!this.injectables) {
      this.loading = true;
      try {
        const response = await axios.get("inject");
        this.injectables = _.get(response, "data", {});
      } catch (error) {
        if (_.get(error, ["response", "status"]) === 401) {
          this.$store.commit("SET", { authenticated: false });
          this.isUnauthenticated = true;
          return;
        } else if (_.get(error, ["response", "status"]) === 403) {
          /* 403 - forbidden
                    1. We are probably logged in as different user, so we should just log out.
                    2. We should arrive here only if local:8080 , otwerwise landing forbidden page is served by laravel.
                    */
          axios({
            method: "post",
            url: "logout",
            baseURL: "/",
          }).then(() => {
            window.location.replace("/");
          });
        }
      }
    }

    let injectableData;
    for (let [key, item] of Object.entries(injectSchema)) {
      injectableData = _.get(this.injectables, item.injectedDataKey, null);
      if (!injectableData) continue;

      if (item.set) {
        if (item.set.length === 1) {
          this.$store.commit("SET", [item.set[0], injectableData]);
        } else {
          this.$store.commit(item.set[0] + "/SET", [item.set[1], injectableData]);
        }
      }
      if (item.commit) this.$store.commit(item.commit, injectableData);
      if (item.action) this.$store.dispatch(item.action, injectableData);
      injectableData = null;
    }
    this.$store.commit("SET", { initialized: true });

    return Promise.resolve();
  }

  async fetchDependencies() {
    const promises = [
      //   this.$store.dispatch("fetchUser", true),
    ];

    return Promise.allSettled(promises).then((values) => {
      Promise.resolve(values);
    });
  }

  async fetchAndInitDependables() {
    const promises = [
      //   this.$store.dispatch("subscription/fetch", true),
    ];

    return Promise.allSettled(promises).then((values) => {
      Promise.resolve(values);
    });
  }
}

export default InitializeApp;
