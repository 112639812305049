import curriculum from './curriculum/routes.js';
import joblisting from './joblisting/routes.js';
import jobapplication from './jobapplication/routes.js';
const meta = { group: 'jobs' };

export default [
  ...curriculum,
  ...joblisting,
  ...jobapplication,
  {
    meta,

    path: "/toopakkumised",
    name: "Jobs",
    component: () =>
      import(/* webpackChunkName: "jobs" */ "./jobs/views/Jobs.vue"),
  },
];
