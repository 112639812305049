<style lang="scss">
</style>

<template>
  <div class="h-100">
    <Advertisement
      max-width="300px"
      elevation="2"
      class="mb-5"
      :route="$route.name"
      placement="SidebarTop"
    />
    <group-card dense class="mb-5 pt-3">
      <div class="text-center">
        <div class="d-flex justify-center">
          <base-logo @click="$router.push({ name: 'Home' })" />
        </div>
        <base-btn class="mt-5" text outlined exact :to="{ name: 'Businesses' }">
          Ettevõtete andmebaas
        </base-btn>
        <div class="mt-5">
          <strong>Viimati lisatud ettevõtted:</strong>
        </div>
      </div>
      <LatestBusinesses />
    </group-card>
    <Advertisement max-width="300px" :route="$route.name" placement="Sidebar" last-is-sticky />
  </div>
</template>

<script>
import Advertisement from "@/blocks/advertisement/components/Advertisement";
import LatestBusinesses from "@/blocks/business/components/LatestBusinesses";
export default {
  components: {
    LatestBusinesses,
    Advertisement,
  },
};
</script>
