import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from "vuetify/lib/util/colors";
import et from "vuetify/es5/locale/et";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { et },
    current: "et",
  },
  breakpoint: {
    /*
    ORIGINAL TRESHOLDS
    thresholds: {
        xs: 600,
        sm: 960,
        md: 1280,
        lg: 1920
    }, */
    // scrollBarWidth: 16,
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1440
    },
    // scrollBarWidth: 24,
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        background: colors.grey.lighten3,
        primary: "#B71C1C",
        accent: "#E41010",
        // secondary: "#000",
        // error: "#FF5252",
        // info: "#2196F3",
        // success: "#4CAF50",
        // warning: "#FFC107"
      },
    },
  }
});
