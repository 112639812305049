<style lang="scss">
.m-w-full {
  max-width: 100% !important;
  height: auto;
}
</style>
<template>
  <span>
    <a
      :is="isLink ? 'a' : 'div'"
      class="d-block"
      :class="{ 'pb-5': paddingBottom, sticky: lastIsSticky && i + 1 === items.length }"
      v-for="(ad, i) in items"
      :key="ad.uuid"
      :href="isLink ? '/advertisement/' + ad.uuid : undefined"
      target="_blank"
    >
      <template>
        <v-img
          v-if="ad.banner"
          :src="ad.banner"
          :max-width="maxWidth"
          :max-height="maxHeight"
          :width="ad.banner_width"
          contain
        />
        <v-sheet
          v-else-if="ad.content"
          :max-width="maxWidth"
          :max-height="maxHeight"
          :color="ad.content.background_color"
          :width="ad.content.width"
          :height="ad.content.height"
        >
          <HtmlContent
            class="pa-2"
            v-if="ad.content.html"
            :content="ad.content.html"
            :replaceables="[
              ['<a ', '<span '],
              ['</a>', '</span>'],
              ['href', 'data-x-href'], // ckeditor wraps images in <a> with href
              ['http://', 'https://'], // ckeditor could send image srces as http
            ]"
          />
        </v-sheet>
      </template>
    </a>
  </span>
</template>

<script>
import HtmlContent from "@/blocks/global/components/HtmlContent";
import AdvertisementItem from "./AdvertisementItem";
import DemoAdvertisement from "./DemoAdvertisement";
export default {
  components: {
    DemoAdvertisement,
    AdvertisementItem,
    HtmlContent,
  },
  props: {
    isLink: { type: Boolean, default: true },
    isBanner: { type: Boolean, default: false },
    backgroundColor: { type: String, default: "grey darken-1" },
    route: {
      type: String,
      required: true,
    },
    placement: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: [Number, String],
      default: undefined,
    },
    maxHeight: {
      type: [Number, String],
      default: undefined,
    },
    item: {
      type: Object,
      default: undefined,
    },
    paddingBottom: {
      type: Boolean,
      default: true,
    },
    lastIsSticky: {
      type: Boolean,
      default: false,
    },
    showOnlyIndex: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      clicked: false,
    };
  },
  computed: {
    items() {
      if (this.item) return [this.item];
      const items = this.$store.state.advertisement.items.filter((item) => {
        for (const placement of item.placements) {
          if (
            (!item.breakpoints || item.breakpoints.includes(this.$vuetify.breakpoint.name)) &&
            (placement.route === "AllRoutes" || placement.route === this.route) &&
            placement.placement === this.placement
          ) {
            return true;
          }
        }
      });
      if (this.showOnlyIndex || this.showOnlyIndex === 0) {
        return items[this.showOnlyIndex] ? [items[this.showOnlyIndex]] : [];
      }

      return items;
    },
  },
};
</script>
