

<template>
  <v-list class="latest-businesses" color="transparent" v-bind="$attrs">
    <v-list-item
      v-for="business in businesses"
      :key="business.slug"
      :to="{ name: 'Business', params: { business_slug: business.slug } }"
      :title="business.title"
    >
      <v-list-item-action class="my-1">
        <v-avatar class="elevation-1" color="white" size="60">
          <v-img max-width="50px" max-height="50px" contain :src="business.logo_avatar"></v-img>
        </v-avatar>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="text-wrap">
          {{ business.title }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    businesses: {
      type: Array,
      required: true,
    },
  },
};
</script>
