<template>
  <div>
    <div v-if="attachments.length > 0" :key="attachments.length">
      <small v-text="`Sisestatud manused`" />
      <div
        v-for="attachment in attachments"
        :key="'attachment-' + attachment.filename"
        class="d-flex justify-space-between mt-2 mb-4"
      >
        <div>
          <div class="d-flex">
            <v-icon left>mdi-paperclip</v-icon>
            <div class="ml-2">
              <a class="link font-weight-thin" :href="attachment.href" target="_blank">
                {{ attachment.filename }}
              </a>
            </div>
          </div>
        </div>
        <div>
          <v-btn
            @click="deleteAttachment(attachment)"
            icon
            text
            small
            title="Kustuta"
            :disabled="disabled"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
    </div>

    <FileUploader
      :disabled="disabled"
      v-bind="$attrs"
      v-if="attachments.length === 0 || (uploadNewFile && attachments.length < maxAttachments)"
      name="room-mixed-color-attachment"
      :key="'new-attachment' + newKey"
      :path="path"
      outlined
      filled
      persistent-hint
      @change="
        directory = $event.directory;
        newKey++;
        uploadNewFile = false;
        attachments.push($event);
      "
      :directory="directory"
    />
    <div v-if="attachments.length >= maxAttachments">
      <v-alert type="warning" tile>
        Rohkem faile üles laadida ei saa.
        <br />
        Sisestada on võimalik kuni {{ maxAttachments }} faili.
      </v-alert>
    </div>
    <base-btn v-else-if="attachments.length && !uploadNewFile" small outlined text @click="uploadNewFile = true">
      <v-icon small left>mdi-paperclip</v-icon>
      <span>Sisesta uus fail</span>
    </base-btn>
  </div>
</template>

<script>
import FileUploader from "./FileUploader";
export default {
  components: {
    FileUploader,
  },
  props: {
    business: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      maxAttachments: 10,
      newKey: 1,
      uploadNewFile: false,
      directory: null,
      attachments: [],
    };
  },
  watch: {
    attachments(newValue) {
      this.$emit("change", this.attachments.length ? this.directory : null);
    },
    attachments_directory(newValue) {
      this.$emit("change", this.attachments.length ? this.directory : null);
    },
  },
  computed: {
    path() {
      if (this.business) {
        return "/business/" + this.business.slug + "/message-attachment";
      }
      return "/landlord/message-attachment";
    },
  },

  methods: {
    deleteAttachment(attachment) {
      this.axios
        .post(this.path + "/delete", {
          directory: attachment.directory,
          filename: attachment.filename,
        })
        .then((response) => {
          this.attachments = this.attachments.filter(
            (item) => item.filename !== attachment.filename,
          );
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

