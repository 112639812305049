const arr = window.location.href.split("/");
const protocol = arr[0] + "//";
const domainWithPort = arr[2]; // with port
const domain = domainWithPort.split(":")[0];

export const fullDomain = protocol + domainWithPort + "/";
export const siteDomain = protocol + domain + "/";


export default siteDomain;
