<template>
  <div>
    <MainNavDrawer />

    <v-sheet
      v-if="topOfHeaderAd"
      :color="_.get(topOfHeaderAd.content, 'background_color', undefined)"
      class="d-flex justify-center"
      tag="a"
      target="_blank"
      :href="'/advertisement/' + topOfHeaderAd.uuid"
    >
      <Advertisement
        :is-link="false"
        :item="topOfHeaderAd"
        route="Global"
        placement="Top"
        :padding-bottom="false"
        :max-height="$vuetify.breakpoint.smAndUp ? '100px' : undefined"
        max-width="100vw"
      />
    </v-sheet>
    <!-- <v-sheet color="#111111" height="16px"></v-sheet> -->

    <MainHeader v-bind="headerProps" key="main" />
    <NewsHeader v-if="news" class="elevation-1" />
    <JobsHeader v-else-if="jobs" class="elevation-1" />

    <div style="min-height: calc(100vh - 80px)">
      <slot></slot>
    </div>

    <MainFooter />
  </div>
</template>

<script>
import JobsHeader from "@/blocks/jobs/jobs/layout/JobsHeader.vue";
import Advertisement from "@/blocks/advertisement/components/Advertisement";
import NewsHeader from "@/blocks/news/components/layout/NewsHeader";
import MainNavDrawer from "./MainNavDrawer";
import MainFooter from "./MainFooter";
import MainHeader from "./MainHeader";

export default {
  components: {
    MainHeader,
    MainFooter,
    MainNavDrawer,
    NewsHeader,
    Advertisement,
    JobsHeader,
  },
  computed: {
    news() {
      return ["News", "NewsPost", "NewsCategory"].includes(this.$route.name);
    },
    jobs() {
      return this.$route.meta && this.$route.meta.group === "jobs";
    },
    headerProps() {
      if (this.news) {
        return {
          dark: true,
          color: "primary",
        };
      }
      return {};
    },
    topOfHeaderAd() {
      return this.$store.state.advertisement.items.find((item) => {
        for (const placement of item.placements) {
          if (
            (!item.breakpoints || item.breakpoints.includes(this.$vuetify.breakpoint.name)) &&
            (placement.route === "AllRoutes" || placement.route === this.route) &&
            placement.placement === "Top"
          ) {
            return true;
          }
        }
      });
    },
  },
};
</script>
