<template>
  <div
    class="section-header w-100 mt-5 mb-4 d-flex justify-space-between align-center "
    :style="'background-color: ' + backgroundColor + '; border-radius: 20px; padding: 4px 16px'"
  >
    <h2 :class="titleClass"><slot></slot></h2>
    <slot name="right"></slot>
  </div>
</template>

<script>
export default {
  props: {
    backgroundColor: {
      type: String,
      default: " #e0e0e0",
    },
    titleClass: {
      type: String,
      default: "text-h4 black--text",
    },
  },
};
</script>

<style></style>
