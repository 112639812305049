<template>
  <ContactForm :url="url" />
</template>

<script>
import ContactForm from "@/blocks/global/components/ContactForm";
export default {
  components: {
    ContactForm,
  },
  data() {
    return {
      url: `landlord/send-message`,
    };
  },
};
</script>
