<style lang="scss">
.vuedl-notification {
  overflow: visible !important;
}
</style>
<template>
  <v-alert
    style="margin: 0; min-width: 300px"
    @input="$emit('submit')"
    :dismissible="dismissible"
    :type="types.includes(color) ? color : 'info'"
    :color="types.includes(color) ? undefined : color"
    :outlined="outlined"
    :prominent="prominent"
    :icon="icon"
  >
    <div class="d-flex align-center">
      <div class="mr-2" v-html="text"></div>
      <DialogActions :actions="actions" />
    </div>
  </v-alert>
</template>
<script>
import DialogActions from "./DialogActions.vue";
import { VAlert } from "vuetify/lib";

export default {
  components: {
    DialogActions,
    VAlert,
  },
  layout: ["notification", { showClose: false }],
  props: {
    color: {
      type: String,
      default: () => "info",
    },
    actions: {
      type: [Array, Object],
      default: () => {},
    },
    text: {
      type: String,
      default: "",
    },
    outlined: Boolean,
    icon: { default: null },
    prominent: Boolean,
    dismissible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      types: ["success", "warning", "error", "info"],
    };
  },
};
</script>
