<style lang="scss">
// @import "@/dependencies.scss";
</style>

<template>
  <div v-if="!$store.state.auth.user.id">
    <v-dialog transition="dialog-top-transition" v-model="dialog" max-width="600">
      <template>
        <AuthForm @authenticated="dialog = false">
          <template #top-right>
            <v-btn icon text small class="ml-4" @click="dialog = false">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </template>
          <template #card-header>
            <div v-if="$store.state.auth.dialogHeaderText">
              <v-alert tile class="mt-3 mt-md-5" type="info">
                {{ $store.state.auth.dialogHeaderText }}
              </v-alert>
            </div>
          </template>
          <template #card-footer></template>
        </AuthForm>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import AuthForm from "./AuthForm";
export default {
  components: {
    AuthForm,
  },
  computed: {
    dialog: {
      get() {
        return this.$store.state.auth.showDialog;
      },
      set(showDialog) {
        this.$store.commit("auth/SET", { showDialog });
      },
    },
  },
  watch: {
    dialog(dialog) {
      if (!dialog) {
        this.$store.commit("auth/SET", { dialogHeaderText: null });
      }
    },
  },
};
</script>
