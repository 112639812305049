export default [
  {
    icon: "mdi-account-hard-hat",
    title: "Ettevõtted",
    to: { name: "Businesses" },
  },
  {
    icon: "mdi-newspaper-variant",
    title: "Uudised",
    to: { name: "News" },
  },
  {
    icon: "mdi-card-account-details",
    title: "Tööpakkumised",
    isNew: true,
    to: { name: "Jobs" },
  },
  {
    icon: "mdi-star",
    title: "Lemmikud",
    to: { name: "BookmarkedBusinesses" },
  },
  {
    icon: "mdi-email",
    title: "Kontakt",
    to: { name: "Page", params: { slug: "kontakt" } },
    hideOnDesktop: true
  },
  {
    icon: "mdi-advertisements",
    title: "Reklaam",
    to: { name: "Page", params: { slug: "reklaam" } },
  },

];
