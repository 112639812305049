<style lang="scss">
@import "@/dependencies.scss";
.footer {
  //   background-color: #071b24;
  color: #b0bec5;

  .social-icons-container {
    @include media-breakpoint-up(md) {
      margin-left: -8px;
    }
  }
}
a.footer__link {
  transition: color 200ms;
  color: #b0bec5 !important;
  &:hover {
    color: var(--v-primary-base) !important;
  }
}
</style>

<template>
  <div class="footer grey darken-4">
    <v-container :fluid="$vuetify.breakpoint.md" class="py-15">
      <v-row justify="space-around" class="text-center text-md-left">
        <v-col cols="12" sm="6" md="3" class="d-flex justify-center justify-lg-start">
          <div>
            <base-logo
              @click="$router.push({ name: 'Home' })"
              monochrome
              style="position: relative; min-width: 100px"
              class="cursor-pointer"
            />
            <div class="social-icons-container my-5 d-flex justify-center d-md-block">
              <SocialIcons :urls="socialMediaUrls" />
            </div>
            <div v-if="contacts.length">
              <div v-for="item in contacts" :key="item.title" class="pb-4">
                <div class="white--text">{{ item.title }}</div>
                <a v-if="item.href" :href="item.href" class="footer__link">
                  {{ item.content }}
                </a>
                <div v-else>
                  {{ item.content }}
                </div>
              </div>
            </div>
          </div>
        </v-col>

        <v-col cols="12" sm="6" md="3" order-md="last">
          <h5 class="text-h5 white--text">Populaarsed ettevõtted</h5>
          <div class="d-flex justify-center d-md-block">
            <PopularBusinesses style="max-width: 320px" class="text-left" dark dense />
          </div>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="3"
          :style="$vuetify.breakpoint.mdAndUp ? `max-width: 180px` : ''"
        >
          <h5 class="text-h5 pb-3 white--text">Uudised</h5>
          <div v-for="(category, i) in postCategories" :key="i">
            <router-link
              class="footer__link"
              :to="{ name: 'NewsCategory', params: { slug: category.slug } }"
            >
              <div class="py-2">
                {{ category.title }}
              </div>
            </router-link>
          </div>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="3"
          :style="$vuetify.breakpoint.mdAndUp ? `max-width: 180px` : ''"
        >
          <h5 class="text-h5 pb-3 white--text">Meist</h5>
          <div v-for="(item, i) in $store.state.footerAboutItems" :key="i">
            <router-link
              class="footer__link"
              :to="item.to ? item.to : { name: $route.name, query: { p: item.slug } }"
            >
              <div class="py-2">
                {{ item.title }}
              </div>
            </router-link>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SocialIcons from "./SocialIcons.vue";
import PopularBusinesses from "@/blocks/business/components/PopularBusinesses";

export default {
  components: {
    PopularBusinesses,
    SocialIcons,
  },
  computed: {
    socialMediaUrls() {
      return {
        facebook: this.$store.state.settings.facebook,
        twitter: this.$store.state.settings.twitter,
        instagram: this.$store.state.settings.instagram,
        youtube: this.$store.state.settings.youtube,
      };
    },
    contacts() {
      return this.$store.getters.setting("footer_items", {});
    },
    postCategories() {
      return this.$store.state.news.categories;
    },
  },
};
</script>
