import Vue from "vue";
import { set as _set } from "lodash";
import _ from "@/common/lodash-plugin";

export const SET = function (state, payload) {
    if (Array.isArray(payload) && payload.length === 2) {
      Vue.set(state, payload[0], payload[1]);
      return;
    }

    if (typeof payload !== "object" || payload === null) {
      return throwSetError(payload);
    }

    for (let [key, value] of Object.entries(payload)) {
      Vue.set(state, key, value);
    }
  };

export const ADD_ITEM_START = function(state, item, field = "items") {
  state[field].unshift(item);
  state[field] = _.clone(_.get(state, field));
};

export const ADD_ITEM = function(state, item, field = "items") {
  state[field].push(item);
  state[field] = _.clone(_.get(state, field));
};

export const ADD_OR_UPDATE_ITEM = function(
  state,
  updatedItem,
  field = "items",
) {
  const item = state[field].find(
    (item) => parseInt(item.id) === parseInt(updatedItem.id),
  );
  if (typeof item === "undefined") {
    return ADD_ITEM(state, updatedItem, field);
  }
  Object.assign(item, updatedItem);
  state[field] = _.clone(state[field]);
};

export const UPDATE_ITEM = function(state, updatedItem, field = "items") {
  const item = state[field].find(
    (item) => parseInt(item.id) === parseInt(updatedItem.id),
  );
  if (typeof item === "undefined") {
    return throwUpdateError(field, updatedItem);
  }
  Object.assign(item, updatedItem);
  state[field] = _.clone(state[field]);
};

export const REMOVE_ITEM = function(state, idOrItem, field = "items") {
  const id = typeof idOrItem === "object" ? idOrItem.id : idOrItem;
  state[field] = state[field].filter((item) => item.id !== id);
};

function throwSetError(payload) {
  console.error(
    "Payload (of mutation SET) must be either array ([key, value]) or object with key value pairs.",
    payload,
  );
}
function throwUpdateError(field, updatetItem) {
  console.error("Cannot update item - target item does not exist in items.", {
    field,
    updatedItem,
  });
}
