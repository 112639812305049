import { capitalize } from "./helpers/capitalize";
import { joinAsSentence } from "./helpers/joinAsSentence";
import { notify } from "./helpers/notify";
import { catchApiErrors } from "./helpers/catchApiErrors";
import { replaceAll } from "./helpers/replaceAll";

const fns = {
  capitalize,
  joinAsSentence,
  notify,
  catchApiErrors,
  replaceAll,
};

export const helpersPlugin = {
  install(Vue) {
    for (let [key, value] of Object.entries(fns)) {
      Vue.prototype["$" + key] = value;
    }
  },
};

export default fns;
