<template>
  <img :width="width" :max-width="width" alt="Ehitussektor" :src="src" />
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: `120px`,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    monochrome: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    src() {
      return this.monochrome
        ? `/storage/base/logo/ehitussektor-logo-monochrome.svg`
        : this.dark
        ? `/storage/base/logo/ehitussektor-logo-white.svg`
        : `/storage/base/logo/ehitussektor-logo.svg`;
    },
  },
};
</script>

<style>
