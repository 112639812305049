
<template>
  <v-container class="h-100">
    <v-row no-gutters class="d-flex justify-center my-10">
      <v-col
        class="flex-grow-1 flex-shrink-0 mb-5"
        :style="$vuetify.breakpoint.mdAndUp ? `max-width: calc(100% - (300px + 2 * 12px))` : null"
      >
        <CardTemplate>
          <template #title>
            <slot name="title"></slot>
          </template>
          <slot></slot>
        </CardTemplate>
      </v-col>
      <v-col style="max-width: calc(300px + 2 * 12px);">
        <MainSidebar />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MainSidebar from "@/blocks/global/components/MainSidebar";
import CardTemplate from "./CardTemplate";
export default {
  components: {
    CardTemplate,
    MainSidebar,
  },
};
</script>
