import { SET } from "@/store/common/_mutations";
import _ from "@/common/lodash-plugin";

const state = {
  routes: {},
};

const getters = {};

const mutations = {
  SET: (state, payload) => {
    SET(state, payload);
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
