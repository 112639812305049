<template>
  <div class="d-flex align-center" style="margin-right: -6px">
    <v-btn
      icon
      dark
      text
      v-bind="$attrs"
      :small="small"
      v-for="platform in availablePlatforms"
      :key="platform.slug"
      :color="colored ? platform.color : undefined"
      target="_blank"
      :href="platform.url"
      :title="platform.title"
    >
      <v-icon :small="small">{{ platform.icon }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    colored: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    platforms: {
      type: Array,
      default: () => [
        { slug: "facebook", title: "Facebook", color: "#3b579d", icon: "mdi-facebook" },
        { slug: "twitter", title: "Twitter", color: "#28aae1", icon: "mdi-twitter" },
        { slug: "instagram", title: "Instagram", color: "#dd2a7b", icon: "mdi-instagram" },
        { slug: "youtube", title: "Youtube", color: "#FF0000", icon: "mdi-youtube" },
        { slug: "homepage", title: "Koduleht", color: "blue-grey darken-4", icon: "mdi-web" },
      ],
    },
    urls: {
      type: Object,
      required: true,
    },
  },

  computed: {
    availablePlatforms() {
      const items = [];
      this.platforms.forEach((platform) => {
        if (this.urls[platform.slug]) {
          items.push({ ...platform, url: this.urls[platform.slug] });
        }
      });
      return items;
    },
  },
};
</script>
