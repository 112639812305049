<script>
import { VCard } from "vuetify/lib";

export default {
  name: "Card",
  props: {
    tile: {
      type: Boolean,
      default: true,
    },
    elevation: {
      type: [String, Number],
      default: 1,
    },
  },
  extends: VCard,
};
</script>
