<style lang="scss">
// @import "@/dependencies.scss";
</style>

<template>
  <div class="d-flex justify-center">
    <AuthForm
      v-if="!authUserId"
      outlined
      class="ma-3"
      style="width: 800px; max-width: 800px"
    ></AuthForm>
  </div>
</template>

<script>
import AuthForm from "../../auth/components/AuthForm.vue";
export default {
  components: {
    AuthForm,
  },
  computed: {
    authUserId() {
      return this.$store.state.auth.user.id;
    },
  },

  watch: {
    authUserId(newValue) {
      if (!newValue) return;

      this.redirectToIntendedRoute(this.$store.state.auth.user);
    },
  },

  methods: {
    redirectToIntendedRoute(user) {
      if (!user) {
        return;
      }

      const intendedName = this._.get(this.$route, ["query", "intended_name"]);

      if (!intendedName) {
        return;
      }

      const route = {
        name: intendedName,
        params: {},
      };

      let intendedParams = this._.get(this.$route, ["query", "intended_params"], null);

      if (!intendedParams) {
        this.$router.push(route);
        return;
      }

      if (typeof intendedParams === "string") intendedParams = [intendedParams];

      if (!Array.isArray(intendedParams)) {
        return;
      }

      for (const parameter of intendedParams) {
        if (parameter === "user_id") {
          route.params.user_id = user.id;
        } else if (parameter === "business_slug") {
          const firstBusinessSlug = this._.get(user, ["managed_businesses", 0, "slug"]);
          if (!firstBusinessSlug) return;
          route.params.business_slug = firstBusinessSlug;
        } else if (parameter) {
          return;
        }
      }
      
      const r = this.$router.resolve(route);
      if (r.resolved.matched.length === 0) return;

      this.$router.push(route);
    },
  },
};
</script>