<template>
  <v-sheet block elevation="0" :max-height="maxHeight" max-width="100%">
    <v-img :src="item.banner" :max-height="maxHeight" max-width="100%" contain ></v-img>
  </v-sheet>
</template>

<script>
const toSnake = (str) =>
  str.replace(/[A-Z]/g, (letter, index) => {
    return index == 0 ? letter : " " + letter;
  });

export default {
  props: {
    maxHeight: {
      type: String,
      default: undefined,
    },
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: "AdaptiveBanner",
    },
  },
  computed: {
    ad() {
      const ad = ads[this.type];
      if (!ad.title) ad.title = toSnake(this.type);
      return ad;
    },
  },
};
</script>

<style>
</style>
