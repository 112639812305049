<style lang="scss">
// @import "@/dependencies.scss";
</style>
<template>
  <div>
    <v-list subheader :three-line="!inDrawer" shaped>
      <v-subheader class="d-inline-block mt-2">
        <small>Sisse logitud:</small>
        <br />
        {{ userData }}
      </v-subheader>

      <v-list-item :to="{ name: 'BookmarkedBusinesses' }">
        <v-list-item-action>
          <v-icon>mdi-star</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Minu lemmikud</v-list-item-title>
          <v-list-item-subtitle v-if="!inDrawer">
            <span class="grey--text">
              Leidsid sirvides ettevõtte, mida soovid edaspidi kiiremini üles leida? Lisa see
              lemmikutesse!
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'BookmarkedJoblistings' }">
        <v-list-item-action>
          <v-icon>mdi-card-account-details-star</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Jälgitud tööpakkumised</v-list-item-title>
          <v-list-item-subtitle v-if="!inDrawer">
            <span class="grey--text">
              Siit leiad üles need tööpakkumised, mille oled märkinud jälgitavaks.
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'SaveCurriculum', params: { user_id: $store.state.auth.user.id } }">
        <v-list-item-action>
          <v-icon>mdi-badge-account-horizontal</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Minu CV</v-list-item-title>
          <v-list-item-subtitle v-if="!inDrawer">
            <span class="grey--text">
              Lisa enda CV ehitussektori portaali ja kandideeri ehitussektori ettevõtete
              tööpakkumistele.
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list subheader shaped>
      <v-list-item :to="{ name: 'MyJobapplications' }">
        <v-list-item-action>
          <v-icon>mdi-briefcase-account</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Minu kandideerimised</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <div v-if="businesses && businesses.length > 0">
      <template v-for="business in businesses">
        <v-divider :key="business.id + 'divider'" />
        <v-list :key="business.id + 'list'" subheader shaped>
          <v-subheader>{{ business.title }} valikud</v-subheader>

          <v-list-item
            exact
            :to="{ name: 'Business', params: { business_slug: business.slug } }"
          >
            <v-list-item-action>
              <v-icon>mdi-briefcase-eye</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Ava ettevõtte leht</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :href="business.manage_url" target="_blank">
            <v-list-item-action>
              <v-icon>mdi-briefcase-edit</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Muuda andmeid</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            exact
            :to="{ name: 'BusinessJoblistings', params: { business_slug: business.slug } }"
          >
            <v-list-item-action>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Tööpakkumised</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            exact
            :to="{ name: 'CreateJoblisting', params: { business_slug: business.slug } }"
          >
            <v-list-item-action>
              <v-icon>mdi-account-multiple-plus</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Lisa tööpakkumine</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </div>
  </div>
</template>
<script>
import LogoutBtn from "./LogoutBtn";
export default {
  components: {
    LogoutBtn,
  },
  props: {
    inDrawer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    businesses() {
      return this._.get(this.$store.state.auth, ["user", "managed_businesses"], false);
    },
    userData() {
      const items = [];
      const name = this._.get(this.$store.state.auth.user, "name");
      const email = this._.get(this.$store.state.auth.user, "email");
      if (name) items.push(name);
      if (email) items.push(email);

      return items.join(", ");
    },
  },
  data() {
    return {
      key: 1,
      btnProps: {
        // rounded: true,
        text: true,
        icon: false,
        fab: false,
        outlined: true,
        block: true,
      },
    };
  },
};
</script>