<style lang="scss">
// @import "@/dependencies.scss";
</style>
<template>
  <v-card class="mx-auto" max-width="400">
    <v-toolbar color="red" dark dense>
      <v-toolbar-title>Valikud</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <MyAccountDialogItems />

    <v-container>
      <v-row justify="center">
        <div class="mx-3 my-2 w-100">
          <LogoutBtn v-bind="btnProps" />
        </div>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import MyAccountDialogItems from './MyAccountDialogItems.vue';
import LogoutBtn from "./LogoutBtn";
export default {
  components: {
    LogoutBtn,
        MyAccountDialogItems,
  },
  computed: {
    businesses() {
      return this._.get(this.$store.state.auth, ["user", "managed_businesses"], false);
    },
    userData() {
      const items = [];
      const name = this._.get(this.$store.state.auth.user, "name");
      const email = this._.get(this.$store.state.auth.user, "email");
      if (name) items.push(name);
      if (email) items.push(email);

      return items.join(", ");
    },
  },
  data() {
    return {
      key: 1,
      btnProps: {
        // rounded: true,
        text: true,
        icon: false,
        fab: false,
        outlined: true,
        block: true,
      },
    };
  },
};
</script>