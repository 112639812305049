<template>
  <v-sheet color="grey darken-4" height="52px" class="white--text">
    <v-container class="py-0 fill-height">
      <div class="d-flex align-center w-100 h-100 text-no-wrap" style="font-size: 14px">
        <div class="ml-1 mr-md-10">
          {{ today }}
        </div>
        <div class="d-flex justify-space-between w-100">
          <div class="d-flex" v-if="$vuetify.breakpoint.mdAndUp">
            <div v-for="item in bottomNavItems" :key="item.title" class="mr-1 d-flex align-center">
              <router-link
                :to="item.to"
                class="white--text py-3 px-3 link"
                :class="{ link__active: item.active }"
                v-ripple="{ class: `white--text` }"
              >
                <v-icon x-small color="white" class="mr-1" v-if="item.icon">
                  {{ item.icon }}
                </v-icon>
                <span>{{ item.title }}</span>
              </router-link>
            </div>
          </div>
          <div v-else></div>
          <SocialIcons class="px-2" :small="$vuetify.breakpoint.mdAndUp" :urls="socialMediaUrls" />
        </div>
      </div>
    </v-container>
  </v-sheet>
</template>

<script>
import SocialIcons from "@/blocks/global/components/SocialIcons";
export default {
  components: {
    SocialIcons,
  },
  computed: {
    postCategories() {
      return this.$store.state.news.categories;
    },
    bottomNavItems() {
      return this.postCategories.map((el) => {
        return {
          ...el,
          to: { name: "NewsCategory", params: { slug: el.slug } },
          active: this.isActive(el.slug),
        };
      });
    },
    socialMediaUrls() {
      return {
        facebook: this.$store.state.settings.facebook,
        twitter: this.$store.state.settings.twitter,
        instagram: this.$store.state.settings.instagram,
        youtube: this.$store.state.settings.youtube,
      };
    },
    today() {
      return new Date().toLocaleDateString("et-EE", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
  methods: {
    isActive(slug) {
      return this.$route.name === "NewsCategory" && this.$route.params.slug === slug;
    },
  },
};
</script>

<style>
</style>
