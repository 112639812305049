
<template>
  <v-card v-bind="{ tile: true, ...$attrs }" class="px-5 py-3">
    <div class="d-flex justify-end">
      <v-btn outlined tile text small href="#" @click.prevent="register = !register">
        <span v-if="!register">Registreeru</span>
        <span v-else>Logi sisse</span>
      </v-btn>
      <slot name="top-right"></slot>
    </div>
    <slot name="card-header"></slot>
    <h1 class="text-h3 text-center mb-4">
      <span v-if="register">Registreeru kasutajaks</span>
      <span v-else>Logi sisse</span>
    </h1>
    <div class="d-md-flex text-center justify-center">
      <SocialAuth @authenticated="onAuthenticated"></SocialAuth>
    </div>
    <v-card-text>
      <v-row justify="center">
        <v-col cols="12" sm="9">
          <div class="text-subtitle-1 font-weight-light text-center mb-3">
            <span v-if="register">..või liitu emaili ja parooliga:</span>
            <span v-else>..või sisene emaili ja parooliga:</span>
          </div>
          <form>
            <v-text-field
              v-if="register"
              v-bind="inputProps"
              class="mb-5"
              name="name"
              v-model="form.name"
              label="Nimi"
              :error-messages="form.error('name')"
              :error="form.state('name')"
              required
            />
            <v-text-field
              v-bind="inputProps"
              class="mb-5"
              name="email"
              v-model="form.email"
              label="E-mail"
              :error-messages="form.error('email')"
              :error="form.state('email')"
              required
            />

            <v-text-field
              v-bind="inputProps"
              class="mb-5"
              name="password"
              v-model="form.password"
              :error-messages="form.error('password')"
              :error="form.state('password')"
              label="Parool"
              required
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
            />

            <v-text-field
              v-if="register"
              v-bind="inputProps"
              class="mb-5"
              name="password_confirmation"
              v-model="form.password_confirmation"
              :error-messages="form.error('password_confirmation')"
              :error="form.state('password_confirmation')"
              label="Korda parooli"
              required
              :type="showConfirmPassword ? 'text' : 'password'"
              :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showConfirmPassword = !showConfirmPassword"
            />

            <div v-if="register">
              <v-checkbox
                v-model="form.accepts_terms"
                :error-messages="form.error('accepts_terms')"
                :error="form.state('accepts_terms')"
                label="Nõustun privaatsuspoliitika ja kasutustingimustega"
                hide-details="auto"
              />
              <TermsDialog @accepted="form.accepts_terms = true" />
            </div>

            <v-checkbox
              v-else
              v-model="form.remember"
              :error-messages="form.error('remember')"
              :error="form.state('remember')"
              label="Jäta mind meelde"
            />

            <v-alert tile v-if="errorMessage" type="error" class="mt-3">
              <span v-html="errorMessage" />
            </v-alert>

            <div class="d-flex justify-end">
              <base-btn
                name="submit"
                color="black"
                dark
                class="mr-4"
                :loading="loading"
                @click="submit"
              >
                <span v-if="register">Registreeru</span>
                <span v-else>Logi sisse</span>
              </base-btn>
            </div>
          </form>
          <div class="mt-7">
            <a
              :href="$router.resolve({ name: 'ResetPassword' }).href"
              class="link text--disabled"
              @click.prevent="onResetPasswordClick"
            >
              Parooli värskendamine
            </a>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <slot name="card-footer"></slot>
  </v-card>
</template>

<script>
import TermsDialog from "@/blocks/global/components/TermsDialog";
import DynamicContent from "@/blocks/page/components/DynamicContent";
import SocialAuth from "./SocialAuth";
import Form from "@/common/form";

export default {
  components: {
    SocialAuth,
    DynamicContent,
    TermsDialog,
  },
  data() {
    return {
      register: this.$route.name === "Register" ? true : false,
      form: new Form({
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        accepts_terms: false,
      }),
      loading: false,
      showPassword: false,
      showConfirmPassword: false,
      errorMessage: null,
      inputProps: {
        outlined: true,
        filled: true,
        hideDetails: "auto",
      },
    };
  },
  methods: {
    submit() {
      this.loading = true;
      this.clearMessages();
      const route = this.register ? "api/auth/register" : "api/auth/login";
      this.form
        .post(route, "/")
        .then((response) => {
          this.onAuthenticated(response.data);
        })
        .catch((error) => {
          this.errorMessage = this._.get(error.response.data, "message", error.message);
          this.loading = false;
        });
    },
    onAuthenticated({ user, intended }) {
      this.loading = false;
      this.$store.dispatch("auth/setUser", user);
      this.$emit("authenticated", { intended, user });
    },
    clearMessages() {
      this.errorMessage = null;
    },
    onResetPasswordClick() {
      this.$emit("redirect");
      this.$router.push({ name: "ResetPassword" });
      this.$store.commit("auth/SET", { showDialog: false });
    },
    $t(getter) {
      const messages = {
        "Sign in with social media": "Logi sisse ",
        "Google account": "Google kontoga",
        "Facebook account": "Facebooki kontoga",
        "Or use email & password": "Või sisene emaili ja parooliga",
        "Remember me": "Jäta mind meelde",
      };
      return messages[getter] ? messages[getter] : getter;
    },
  },
};
</script>
