import Vue from "vue";

const mainAutoComponents = require.context("@/blocks/global/components/auto", true, /\.vue$/i);
for (const components of [mainAutoComponents]) {
    components.keys().map(key =>
        Vue.component(
            key
                .split("/")
                .pop()
                .split(".")[0],
            components(key).default
        )
    );
}
