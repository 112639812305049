export const replaceAll = function (
  str,
  replacable,
  replacement,
  ignore
) {
  return str.replace(
    new RegExp(
      replacable.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g, "\\$&"),
      ignore ? "gi" : "g",
    ),
    typeof replacement == "string" ? replacement.replace(/\$/g, "$$$$") : replacement,
  );
}