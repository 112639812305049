/**
 * Join the array as sentence.
 *
 * Is like arr.join() except that it can have different last separator
 *
 * @param {array} arr
 * @param {string} separator
 * @param {string} lastSeparator
 * @returns
 */
export const joinAsSentence = function (arr, separator, lastSeparator) {
    if (arr.length === 1) return arr[0]
    let text = ''
    for (let i = 0; i < arr.length; i++) {
        if (i > 0)
            text += i + 1 === arr.length ? lastSeparator : separator
        text += arr[i]
    }
    return text
};
