<template>
  <v-snackbar
    style="z-index: 1000"
    app
    color="#071b24"
    v-model="show"
    multi-line
    tile
    :timeout="null"
  >
    Sellel veebilehel kasutatakse küpsiseid. Kasutamist jätkates nõustute meie privaatsuspoliitika
    ja küpsiste kasutamise
    <TermsDialog
      content-class="cookie-alert-terms-dialog"
      style="z-index: 1001"
      @accepted="onAccept()"
    >
      <template v-slot:activator="{ on, attrs }">
        <slot name="activator" v-bind="{ on, attrs }">
          <a class="mt-3" small outlined text tile v-bind="attrs" v-on="on">tingimustega</a>
        </slot>
      </template>
    </TermsDialog>
    <template v-slot:action="{ attrs }">
      <base-btn class="mr-md-1" v-bind="attrs" outlined text @click="onAccept()">Nõustun</base-btn>
    </template>
    <span>.</span>
  </v-snackbar>
</template>

<script>
import TermsDialog from "./TermsDialog";
export default {
  components: {
    TermsDialog,
  },
  data() {
    return {
      show: !localStorage.getItem("cookieNoticeAccepted"),
    };
  },
  methods: {
    onAccept() {
      localStorage.setItem("cookieNoticeAccepted", true);
      this.show = false;
    },
  },
};
</script>
