/**
 *
 * @param {string} url
 * @param {string} name
 * @param {function} listener If popup sends message, then you can access message: function listener(evt) { let message = evt.data }
 * @param {boolean} once Should we listen to the event only once? If false, then you should remove the listener manually outside of this function eg: beforeDestroy() {window.removeEventListener("message", this.evListener);},
 *
 */
const openPopupWindow = (url, name, listener, once = true, features = null) => {
  let windowObjectReference = null;
  let previousUrl = null;
  // remove any existing event listeners
  window.removeEventListener("message", listener);

  // window features
  const strWindowFeatures = features
    ? features
    : "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";

  if (windowObjectReference === null || windowObjectReference.closed) {
    /* if the pointer to the window object in memory does not exist
      or if such pointer exists but the window was closed */
    windowObjectReference = window.open(url, name, strWindowFeatures);
  } else if (previousUrl !== url) {
    /* if the resource to load is different,
      then we load it in the already opened secondary window and then
      we bring such window back on top/in front of its parent window. */
    windowObjectReference = window.open(url, name, strWindowFeatures);
    windowObjectReference.focus();
  } else {
    /* else the window reference must exist and the window
      is not closed; therefore, we can bring it back on top of any other
      window with the focus() method. There would be no need to re-create
      the window or to reload the referenced resource. */
    windowObjectReference.focus();
  }

  // add the listener for receiving a message from the popup
  // 'message' is not just any string. https://developer.mozilla.org/en-US/docs/Web/Events
  window.addEventListener("message", event => listener(event), {
    once: once,
  });
  // assign the previous URL
  previousUrl = url;

  return windowObjectReference;
};

export default openPopupWindow;
