<style lang="scss">
.group-card__title div {
  text-align: center;
  width: 100%;
  @include media-breakpoint-up(sm) {
    text-align: left;
  }
}
</style>
<template>
  <base-card class="group-card" :elevation="0" :tag="tag">
    <v-card-title
      v-if="!hideTitle"
      :class="`px-${dense ? 2 : 5} pt-3 pb-1 group-card__title text-h${
        dense ? 5 : 4
      } font-weight-medium`"
    >
      <div style="text--secondary text-center black white--text">
        <slot name="title">{{ title }}</slot>
      </div>
    </v-card-title>

    <div :class="`mx-${dense ? 2 : 5}`">
      <slot></slot>
    </div>
  </base-card>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: null,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: "section",
    },
  },
};
</script>
