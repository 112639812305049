import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
// import csrfToken from "./csrf-token";
// import store from "@/store";

const Axios = {
  init() {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        this.reportError(error);
        throw error;
      },
    );

    /**
     * ! Not required with laravel-sanctum
     * if (csrfToken) {
     *     axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
     * }
     */

    axios.defaults.withCredentials = true;
    axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
    const language = document.querySelector("html").getAttribute("lang");
    axios.defaults.headers.common["Accept-Language"] = language;
    // axios.defaults.headers.common["Authorization"] = 'Bearer 1|QBwDJIi8uUHhhA7IwWMK6LY2LlkTTWs4vT6viwmL';
    axios.defaults.baseURL = "/api";
    Vue.use(VueAxios, axios);
  },

  /**
   * ReportError
   */
  reportError(error) {
    // Continue only if error status code starts with 5 or is 499 (499 will
    // be used to send snackbar messages in our app)

    if (
      (error.response && error.response.status === 499) ||
      (error.response.status + "").charAt(0) === "5"
    ) {
      if (error.response.data.message === "Server Error") return;

    //   store.commit("SET", {
    //     errorSnackbarVisible: true,
    //     errorSnackbarContent: error.response.data.message,
    //   });
    }
  },
};

export default Axios;
