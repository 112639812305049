
<template>
  <v-container class="h-100">
    <v-row no-gutters class="d-flex justify-center my-10">
      <v-col cols="12" sm="10" md="9" lg="8">
        <CardTemplate>
          <template #title>
            <slot name="title"></slot>
          </template>
          <slot></slot>
        </CardTemplate>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardTemplate from "./CardTemplate";
export default {
  components: {
    CardTemplate,
  },
};
</script>
