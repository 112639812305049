<template>
  <v-btn v-bind="$attrs" :tile="tile" v-on="$listeners">
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  props: {
    tile: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
