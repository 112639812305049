import { SET } from "@/store/common/_mutations";
import _ from "@/common/lodash-plugin";

const state = {
  user: {
    id: null,
    managed_businesses: null,
  },
  userJobapplications: [],
  showDialog: false,
  dialogHeaderText: "",
};

const getters = {
  isAuthenticated: (state) => {
    return state.user && state.user.id !== null
  },
};

const mutations = {
  SET: (state, payload) => {
    SET(state, payload);
  },
};

const actions = {
  setUser({ commit }, user) {
    if (!user) return;
    commit("SET", { user });
  },
  logOut({ commit }, axios) {
    axios({
      method: "post",
      url: "logout",
      baseURL: "/",
    })
      .then(() => {
        window.location.replace("/");
      })
      .catch(function (error) {
        console.error(error);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
