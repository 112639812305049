export default [
  {
    path: `/ehitusfirmad`,
    name: "Businesses",
    component: () =>
      import(/* webpackChunkName: "business" */ "./views/Businesses.vue"),
    // meta: { title: "Ettevõtted" },
  },
  {
    meta: { title: "Ettevõte" },
    name: "Business",
    path: `/ehitusfirmad/:business_slug`,
    component: () =>
      import(/* webpackChunkName: "business" */ "./views/Business.vue"),
  },
  {
    path: `/lemmikud`,
    name: "BookmarkedBusinesses",
    component: () =>
      import(
        /* webpackChunkName: "business" */ "./views/BookmarkedBusinesses.vue"
      ),
    // meta: { title: "Lemmikud ettevõtted" },
  },
  {
    path: `/kontaktiteade`,
    name: "MailMessageDetails",
    component: () =>
      import(/* webpackChunkName: "business" */ "./views/MailMessageDetails.vue"),
    meta: { title: "Kontaktiteade" },
  },
];
