const meta = { group: 'jobs' };

export default [
  {
    meta,
    path: `/jobs/:business_slug/:joblisting_id/apply`,
    name: "SaveJobapplication",
    component: () =>
      import(/* webpackChunkName: "jobs" */ "./views/SaveJobapplication.vue"),
  },
  {
    meta,
    path: `/minu-kandideerimised`,
    name: "MyJobapplications",
    component: () =>
      import(/* webpackChunkName: "jobs" */ "./views/MyJobapplications.vue"),
  },
];
