<template>
  <v-file-input
    :loading="uploading"
    :readonly="uploading"
    :error="errorState"
    :error-messages="errorMessages"
    v-model="file"
    v-bind="$attrs"
    @change="post"
    placeholder="Sisesta fail"
    hint="Lubatud suurus: kuni 5 mb"
    :persistent-hint="true"
  ></v-file-input>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    path: {
      type: String,
      required: true,
    },
    directory: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      localDirectory: this.directory,
      file: null,
      responseItem: this.value,
      errorState: null,
      errorMessages: null,
      uploading: false,
    };
  },
  methods: {
    post() {
      this.errorState = null;
      this.errorMessages = null;
      if (!this.file) return;
      this.uploading = true;

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      let form = new FormData();
      form.append("file", this.file);
      form.append("directory", this.localDirectory ? this.localDirectory : "");

      this.axios
        .post(this.path, form, config)
        .then((response) => {
          this.responseItem = response.data;
          this.localDirectory = response.data.directory;
          this.$emit("change", response.data);
        })
        .catch((error) => {
          console.error(error);
          this.errorState = true;
          this.errorMessages = this._.get(
            error.response.data,
            ["errors", "file"],
            [error.response.data.message],
          );
        })
        .finally(() => {
          this.uploading = false;
        });
    },
  },
};
</script>
