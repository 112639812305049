
<style lang="scss">
.login-link:hover span {
  text-decoration: underline;
}
</style>
<template>
  <v-sheet :color="color" style="transition: background-color 0.5s ease">
    <v-container class="py-0 px-0">
      <v-app-bar
        color="transparent"
        class="px-0"
        elevation="0"
        dense
        :dark="dark"
        :height="$vuetify.breakpoint.mdAndDown ? '56px' : '80px'"
      >
        <div class="mr-5 cursor-pointer d-flex align-center" @click="onLogoClick()">
          <base-logo
            key="dark"
            :dark="dark"
            :width="!$vuetify.breakpoint.mdAndDown ? `120px` : `100px`"
          />
        </div>
        <v-spacer></v-spacer>

        <v-tabs v-if="!$vuetify.breakpoint.mdAndDown" v-model="tab" class="v-tabs__slider-top">
          <template v-for="n in navItems">
            <v-tab
              :to="n.to"
              :key="n.title"
              class="text-transform-none"
              :style="'font-size: 1rem'"
              :class="{ 'white--text': dark }"
            >
              <v-badge content="Uus" color="purple" v-if="n.isNew">
                {{ n.title }}
              </v-badge>
              <span v-else>{{ n.title }}</span>
            </v-tab>
          </template>
        </v-tabs>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-no-wrap text-no-wrap">
          <div class="d-none d-md-block">
            <a
              v-if="!$store.state.auth.user.id"
              class="login-link d-flex align-center text-body-2 mr-3"
              :class="dark ? 'white--text' : 'secondary--text'"
              :href="$router.resolve({ name: 'Login' }).href"
              @click.prevent="$store.commit('auth/SET', { showDialog: true })"
            >
              <v-icon small left>mdi-login</v-icon>
              <span>Liitu / Logi sisse</span>
            </a>

            <MyAccountDialog v-else />
          </div>
          <base-btn
            v-if="!firstBusinessSlug"
            :color="dark ? 'white' : 'accent'"
            :outlined="dark"
            :small="$vuetify.breakpoint.xs"
            :to="{ name: 'Page', params: { slug: 'lisa-ettevote' } }"
          >
            Lisa ettevõte
          </base-btn>
          <base-btn
            v-else
            :color="dark ? 'white' : 'accent'"
            :outlined="dark"
            :small="$vuetify.breakpoint.xs"
            :to="{ name: 'CreateJoblisting', params: { business_slug: firstBusinessSlug } }"
          >
            Lisa töökuulutus
          </base-btn>
        </div>

        <v-app-bar-nav-icon
          class="ml-2"
          v-if="$vuetify.breakpoint.mdAndDown"
          @click="showDrawer = true"
        ></v-app-bar-nav-icon>
      </v-app-bar>
    </v-container>
  </v-sheet>
</template>

<script>
import MyAccountDialog from "@/blocks/auth/components/MyAccountDialog";
import navItems from "@/blocks/global/main-nav-items";
export default {
  components: {
    MyAccountDialog,
  },
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "white",
    },
  },
  data() {
    return {
      navItems: navItems.filter((el) => el.hideOnDesktop !== true),
      tab: null,
    };
  },
  methods: {
    name() {
      this.$refs.tabs.callSlider();
    },
    onLogoClick() {
      this.tab = "/uudised";
      if (this.$route.name === "Home") return;
      this.$router.push({ name: "Home" });
    },
  },
  computed: {
    firstBusinessSlug() {
      const businesses = this._.get(this.$store.state.auth.user, "managed_businesses");
      if (!businesses || !businesses.length) return null;
      return businesses[0].slug;
    },
    showDrawer: {
      get() {
        return this.$store.state.showDrawer;
      },
      set(value) {
        this.$store.commit("SET", { showDrawer: value });
      },
    },
  },
};
</script>

