import { SET } from "@/store/common/_mutations";
import advertisement from "@/blocks/advertisement/store.js";
import auth from "@/blocks/auth/store.js";
import business from "@/blocks/business/store.js";
import joblisting from "@/blocks/jobs/joblisting/store.js";
import jobapplication from "@/blocks/jobs/jobapplication/store.js";
import news from "@/blocks/news/store.js";
import page from "@/blocks/page/store.js";
import curriculum from "@/blocks/jobs/curriculum/store.js";
// import snackbar from "./snackbar.store.js";
import _ from "@/common/lodash-plugin";

export default {
  state: {
    settings: {},
    content: {},
    csrf: null,
    showDrawer: false,
    loading: [],
    footerAboutItems: [],

    /**
     * This adds a possibility to load some secondary just a littlebit after site is mounted
     */
    afterMounted: false,

    /**
     *
     */
    cache: {},

    /**
     *
     */
    initialized: false,
  },

  getters: {
    setting:
      (state) =>
        (key, def = "") => {
          const value = _.get(state.settings, key);
          return value ? value : def;
        },
    content:
      (state) =>
        (key, def = "") => {
          const value = _.get(state.content, key);
          return value ? value : def;
        },
    appLoading: (state) => state.loading.length > 0,
    isLoading: (state) => (key) => state.loading.includes(key),
  },

  mutations: {
    SET: (state, payload) => {
      SET(state, payload);
    },
    SET_CACHE: (state, payload) => {
      SET(state.cache, payload);
    },
    UNSET_CACHE: (state, field) => {
      _.unset(state.cache, field);
    },
    START_LOADING: (state, payload) => {
      state.loading.push(payload);
    },
    STOP_LOADING: (state, payload) => {
      state.loading = state.loading.filter((item) => item !== payload);
    },
  },

  actions: {},

  modules: {
    advertisement,
    auth,
    business,
    news,
    page,
    curriculum,
    joblisting,
    jobapplication
  },
};
