<template>
  <v-navigation-drawer width="280" app shaped v-model="showDrawer" absolute temporary style="z-index: 500">
    <v-list-item>
      <v-list-item-content>
        <div>
          <base-logo
            @click="$router.push({ name: 'Home' })"
            class="cursor-pointer"
            width="116px"
            :dark="false"
          />
        </div>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn text icon @click="showDrawer = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <div v-for="(group, i) in groups" :key="`group-` + i">
      <v-divider v-if="group.divider"></v-divider>
      <v-list dense :subheader="!!group.subheader" shaped>
        <v-subheader v-if="group.subheader">{{ group.subheader }}</v-subheader>
        <template v-for="item in group.items">
          <v-list-item
            color="primary"
            v-if="!item.items"
            :key="item.title"
            exact
            v-bind="item.props"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>{{ item.title }}</v-list-item-content>
          </v-list-item>
          <v-list-group
            v-else
            :key="item.title"
            color="primary"
            v-model="item.active"
            dense
            :prepend-icon="item.icon"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              exact
              :to="onChildClick(child)"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </div>

    <v-divider class="pb-3"></v-divider>
    <MyAccountDialogItems :in-drawer="true" v-if="$store.state.auth.user.id" />
    <v-list-item>
      <v-list-item-content>
        <LogoutBtn block color="primary" v-if="$store.state.auth.user.id" />
        <LoginBtn block color="primary" v-else />
      </v-list-item-content>
    </v-list-item>
  </v-navigation-drawer>
</template>

<script>
import MyAccountDialogItems from "../../auth/components/MyAccountDialogItems.vue";
import LogoutBtn from "@/blocks/auth/components/LogoutBtn";
import LoginBtn from "@/blocks/auth/components/LoginBtn";
import navItems from "@/blocks/global/main-nav-items";

export default {
  components: {
    LoginBtn,
    LogoutBtn,
    MyAccountDialogItems,
  },
  data() {
    return {
      navItems: navItems
        .map((el) => {
          return { ...el, props: { to: el.to } };
        })
        .filter((el) => el.title !== "Lemmikud"),
    };
  },
  computed: {
    showDrawer: {
      get() {
        return this.$store.state.showDrawer;
      },
      set(value) {
        this.$store.commit("SET", { showDrawer: value });
      },
    },

    groups() {
      return [{ subheader: "", items: this.navItems }];
    },
  },

  methods: {
    onChildClick(item) {
      if (item.to) return item.to;
      return {
        name: this.$route.name,
        query: { p: this.$route.query.p === item.title ? 0 : item.title },
      };
    },
  },
};
</script>
