<template>
  <v-app :style="{ background: $vuetify.theme.themes['light'].background }">
    <v-main>
      <v-progress-linear
        absolute
        style="z-index: 1000"
        color="blue"
        :indeterminate="$store.getters.appLoading"
        :background-color="$store.getters.appLoading ? 'rgba(255,255,255,0.5)' : 'transparent'"
      />

      <MainLayout>
        <router-view v-if="$store.state.initialized" />
      </MainLayout>
      <AuthDialog />
      <CookieNotice v-if="!$store.state.auth.user.id" />
    </v-main>
  </v-app>
</template>
<script>
import CookieNotice from "./blocks/global/components/CookieNotice";
import AuthDialog from "./blocks/auth/components/AuthDialog";
import MainLayout from "./blocks/global/components/MainLayout";
import InitializeApp from "@/actions/InitializeApp";

export default {
  components: {
    MainLayout,
    AuthDialog,
    CookieNotice,
  },
  name: "App",
  //   head: {
  //     titleTemplate: "%s | Ehitussektor",
  //   },
  created() {
    new InitializeApp(this.$store).init();
  },
  mounted() {
    setTimeout(() => {
      this.$store.commit("SET", { afterMounted: true });
    }, 300);
  },
};
</script>
