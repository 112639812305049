export const catchApiErrors = function (
  error,
  config = {
    message: "Vabandame - midagi läks valesti!",
    type: "error",
    color: "error",
  },
) {
  const message = error.response.message
    ? error.response.message
    : config.message;
  this.$notify(message, config.type, config);
};
