import {
  SET,
  //   ADD_ITEM_START,
  //   ADD_ITEM,
  ADD_OR_UPDATE_ITEM,
  //   UPDATE_ITEM,
  //   REMOVE_ITEM,
} from "@/store/common/_mutations";
import _ from "@/common/lodash-plugin";
import _getters from "@/store/common/_getters";
import axios from "axios";

const paginatedBusinessesState = {
  items: [], // businesses
  total: null,
  loadedPage: null,
  loading: false,
  query: {
    page: 1,
    per_page: 6,
    category_ids: [],
    region_ids: [],
    order_by: null,
    order_desc: null,
    excluded_ids: [],
    bookmarked_by: null,
    search_term: null,
    featured_first: true
  },
};

const state = {
  categories: [],
  regions: [],
  latestBusinesses: [],
  popularBusinesses: [],
  popularSearchTerms: [],
  ..._.cloneDeep(paginatedBusinessesState),
};

const getters = {
  ..._getters,
};

const mutations = {
  SET: (state, payload) => {
    SET(state, payload);
  },
  SET_QUERY: (state, payload) => {
    SET(state.query, payload);
    // SET(state, { query: _.cloneDeep(state.query) });
  },
  ADD_OR_UPDATE_ITEM: (state, updatedBusiness) => {
    ADD_OR_UPDATE_ITEM(state, updatedBusiness);
  },
};

const actions = {
  syncItems({ commit, getters }, businesses) {
    for (const business of businesses) {
      commit("ADD_OR_UPDATE_ITEM", business);
    }
  },
  async fetchItems({ state, commit, getters, dispatch }) {
    if (state.loading) return;

    commit("SET", { loading: true });
    try {
      const response = await axios.post("business/query", state.query);
      const loadedPage = response.data.query.page;
      const isNewQuery = loadedPage === 1;
      const itemsReceived = response.data.data;

      const results = {
        items: isNewQuery ? itemsReceived : [...state.items, ...itemsReceived],
        query: {
          ...response.data.query,
          order_by: null,
          order_desc: null,
        },
        total: response.data.meta.total,
        loadedPage: loadedPage,
      };
      commit("SET", results);
    } finally {
      commit("SET", { loading: false });
    }
  },

  fetchFirstPageItems({ state, commit, dispatch }, skipIfFetched = false) {
    if (skipIfFetched && state.loadedPage === state.query.page) return;
    commit("SET_QUERY", { page: 1 });
    dispatch("fetchItems");
  },

  fetchNextPageItems({ state, commit, dispatch }) {
    if (state.loading) return;
    const page = state.query.page + 1;
    commit("SET_QUERY", { page });
    dispatch("fetchItems");
  },
};

const bookmarkedBusinessesModule = {
  namespaced: true,
  state: () => paginatedBusinessesState,
  getters: getters,
  mutations: mutations,
  actions: actions,
};

export default {
  namespaced: true,
  state: () => state,
  getters,
  mutations,
  actions,
  modules: {
    bookmarked: bookmarkedBusinessesModule,
  },
};
