<template>
  <div class="">
    <h2
      class="font-weight-bold d-flex justify-space-between"
      :class="{
        'text--secondary text-h6 text-uppercase': secondary,
        'text-h4 mt-2 mb-4': !secondary,
      }"
    >
      <div>
        <slot></slot>
      </div>

      <div><slot name="right"></slot></div>
    </h2>
    <v-divider v-if="!borderless" class="my-2"></v-divider>
  </div>
</template>
<script>
export default {
  props: {
    borderless: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
