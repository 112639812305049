let injectables;
const metaApp = document.head.querySelector('meta[name="app"]');
const injectablesMissing =
  !metaApp ||
  ["<?php echo $injectables;?>", "{!!$injectables!!}"].includes(
    metaApp.content.replace(/ /g, ""),
  )
    ? true
    : false;

if (injectablesMissing) {
  injectables = null;
} else {
  try {
    injectables = JSON.parse(metaApp.content);
  } catch (error) {
    console.error("There were error while accessing injectables!");
    console.error(error);
    injectables = null;
  }
}
export default injectables;
